import { LinkOptions } from '@models/link';

export const headerNavigation: Array<LinkOptions> = [
	{ name: 'Направления', url: '/directions' },
	{ name: 'Журнал', url: '/blog' },
	{ name: 'Карьера', url: '/career' },
	{ name: 'Культура', url: '/culture' },
	{ name: 'Агона', url: '/agona' },
	{ name: 'Продукты', url: '/products' },
];
