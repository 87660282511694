import { Routes } from '@angular/router';
import { HeaderTheme } from '@models/enums/header-theme.enum';
import { NotFoundComponent } from '@pages-public/not-found/not-found.component';

export const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		loadChildren: () => import('@pages-public/index/index.module').then(m => m.IndexModule),
		data: {
			mainPage: true,
		},
	},
	{
		path: 'directions',
		children: [
			{
				path: '',
				pathMatch: 'full',
				loadChildren: () => import('@pages-public/directions/directions.module').then(c => c.DirectionsModule),
			},
			{
				path: 'team',
				loadChildren: () =>
					import('@pages-public/directions/services-team/services-team.module').then(
						c => c.ServicesTeamModule,
					),
			},
			{
				path: 'development',
				loadComponent: () =>
					import('@pages-public/directions/services-development/services-development.page').then(
						c => c.ServicesDevelopmentPage,
					),
			},
			{
				path: 'ai',
				loadChildren: () =>
					import('@pages-public/directions/techno-ai/techno-ai.module').then(c => c.TechnoAiModule),
			},
			{
				path: 'devs',
				loadChildren: () =>
					import('@pages-public/directions/techno-devs/techno-devs.module').then(c => c.TechnoDevsModule),
			},
		],
	},
	{
		path: 'credo',
		loadChildren: () => import('@pages-public/credo/credo.module').then(m => m.CredoModule),
	},
	{
		path: 'agona',
		loadChildren: () => import('@pages-public/agona/agona.module').then(m => m.AgonaModule),
		data: {
			theme: HeaderTheme.TRANSPARENT,
			textColorTheme: HeaderTheme.BLUE,
			reverseTheme: HeaderTheme.BLUE,
		},
	},
	{
		path: 'blog',
		children: [
			{
				path: '',
				pathMatch: 'full',
				loadComponent: () => import('@pages-public/blog/blog.page').then(c => c.BlogPage),
			},
			{
				path: ':id',
				loadComponent: () => import('@pages-public/article/article.page').then(c => c.ArticlePage),
			},
		],
	},
	{
		path: 'career',
		children: [
			{
				path: '',
				pathMatch: 'full',
				data: {
					theme: HeaderTheme.TRANSPARENT,
					textColorTheme: HeaderTheme.LIGHT,
					reverseTheme: HeaderTheme.LIGHT,
					reverseTextColorTheme: HeaderTheme.DARK,
				},
				loadChildren: () => import('@pages-public/career/career.module').then(m => m.CareerModule),
			},
			{
				path: 'list',
				loadComponent: () =>
					import('@pages-public/career/career-list/career-list.page').then(c => c.CareerListPage),
				data: {
					theme: HeaderTheme.TRANSPARENT,
					textColorTheme: HeaderTheme.DARK,
					reverseTheme: HeaderTheme.LIGHT,
				},
			},
			{
				path: 'vacancy/:slug',
				loadComponent: () => import('@pages-public/career/vacancy/vacancy.page').then(c => c.VacancyPage),
				data: {
					theme: HeaderTheme.LIGHT,
					textColorTheme: HeaderTheme.DARK,
				},
			},
		],
	},
	{
		path: 'culture',
		loadChildren: () => import('@pages-public/culture/culture.module').then(m => m.CultureModule),
	},
	{
		path: 'products',
		loadChildren: () => import('@pages-public/products/products.module').then(m => m.ProductsModule),
	},
	{
		path: 'contacts',
		loadChildren: () => import('@pages-public/contacts/contacts.module').then(m => m.ContactsModule),
	},
	{
		path: 'compliance',
		loadComponent: () => import('@pages-public/compliance/compliance.page').then(c => c.CompliancePage),
	},
	{
		path: 'three-plus-one',
		loadComponent: () => import('@pages-public/three-plus-one/three-plus-one.page').then(c => c.ThreePlusOnePage),
	},
	{
		path: 'privacy-policy',
		loadComponent: () => import('@pages-public/privacy-policy/privacy-policy.page').then(m => m.PrivacyPolicyPage),
	},
	{
		path: '404',
		component: NotFoundComponent,
	},
	{
		path: '**',
		redirectTo: '404',
	},
];
